<template>
  <div class="flex items-center">
    <div class="flex items-center" v-for="(item, index) in list" :key="index">
      <div v-if="item.icon !== null" v-html="item.icon" class="mr-2"></div>
      <p :class="classHover(item)" @click="redirect(item)">
        {{ item.title }}
      </p>
      <div v-if="index < list.length - 1" class="mx-1" v-html="$icon.svg.arrowRightwardFillEva"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: ['list'],
  methods: {
    redirect(item) {
      console.log(item)
      if (item.routeName !== null) {
        this.$router.push({ name: item.routeName })
      }
    },
    classHover(item) {
      let classes = ''
      if (this.$route.name === item.routeName) {
        classes += 'text-primary-dark-2 font-bold'
      }

      if (item.routeName !== null) {
        classes += ' cursor-pointer hover-primary'
      }
      return classes
    }
  }
}
</script>
