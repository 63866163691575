import { useRouter } from 'vue-router'
import DropdownAbsolute from '@/views/shared-components/DropdownAbsolute.vue'
import {
  useStore
} from 'vuex'
import {
  environment
} from '@/utils'

export default {
  name: 'ProfileDropdown',
  components: {
    DropdownAbsolute
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    // console.log('anyy', store.state.token)
    const logout = async () => {
      // const response = await sendingLogOutAccount(store.state.token.idToken)
      // if (!response.error) {
      if ('lastManifest' in localStorage) {
        localStorage.removeItem('lastManifest')
      }
      window.location.href = `${environment.getHydraApi()}oauth2/sessions/logout?id_token_hint=${store.state.token.idToken}&post_logout_redirect_uri=${window.location.origin}`
      store.dispatch('logoutAccount')
      // console.log('ini store', store.state.token.idToken)
      // console.log('lklkk', `${environment.getHydraApi()}oauth2/sessions/logout?id_token_hint=${store.state.token.idToken}`)
      // router.push({ name: 'home' })
      // }
    }

    const toProfilAgen = () => {
      router.push({ name: 'profil-agen' })
    }

    const changePassword = () => {
      if (store.getters['appActiveUser/getAgent']?.id) {
        router.push({ name: 'change-password' })
      } else {
        router.push({ name: 'change-password-ansurance' })
      }
    }

    const reload = () => {
      window.location.reload()
    }

    return {
      logout,
      changePassword,
      reload,
      toProfilAgen
    }
  }
}
