import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "dropdown-list-menu" }
const _hoisted_2 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_3 = { ref: "listItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", {
      class: _ctx.classBody(),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleClick()))
    }, [
      (_ctx.checkIsCanRedirect())
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: "flex items-center w-full",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.directRouter()))
          }, [
            _createVNode("div", {
              class: "icon mr-3",
              innerHTML: _ctx.listMenu.icon
            }, null, 8, ["innerHTML"]),
            _createVNode("h6", null, _toDisplayString(_ctx.listMenu.label), 1)
          ]))
        : (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("div", {
              class: "icon mr-3",
              innerHTML: _ctx.listMenu.icon
            }, null, 8, ["innerHTML"]),
            _createVNode("h6", null, _toDisplayString(_ctx.listMenu.label), 1)
          ])),
      (_ctx.listMenu.items && _ctx.listMenu.items.length > 0)
        ? (_openBlock(), _createBlock("div", {
            key: 2,
            class: _ctx.classIcon(),
            innerHTML: _ctx.icon.svg.arrowDownwardFillEva
          }, null, 10, ["innerHTML"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.listMenu && _ctx.listMenu.items && _ctx.listMenu?.items.length > 0)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: _ctx.classExpandable(),
          ref: "expandable"
        }, [
          _createVNode("ul", _hoisted_3, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listMenu.items, (item, idxList) => {
              return (_openBlock(), _createBlock("div", {
                class: _ctx.classItemMenu(item),
                key: 'menu-item-' + idxList,
                onClick: ($event: any) => (_ctx.directRouter(item.route))
              }, [
                _createVNode(_component_Icon, {
                  icon: _ctx.icons.circle,
                  class: "mr-2 circle-icon",
                  height: ".8rem",
                  width: ".8rem"
                }, null, 8, ["icon"]),
                _createVNode("h6", null, _toDisplayString(item.label), 1)
              ], 10, ["onClick"]))
            }), 128))
          ], 512)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}