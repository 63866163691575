
import DropdownListMenu from '@/views/shared-components/DropdownListMenu.vue'
import {
  ref,
  computed
} from 'vue'
import {
  useStore
} from 'vuex'
import SwitchMenu from '@/views/shared-components/switch/SwitchMenu.vue'
import {
  menuAdminAgent,
  menuKurir
} from '@/utils/helpers/mappingMenus'

interface IManagementGroup {
  key: number
  label: string
}

export default {
  name: 'LeftMenu',
  setup() {
    const version = localStorage.getItem('version')
    const store = useStore()
    const activeSlider = computed({
      get: () => store.state.toggleMenu,
      set: (val) => {
        store.dispatch('setToggleMenu', val)
      }
    })

    const menusAdmin = ref(menuAdminAgent)

    const menusKurir = ref(menuKurir)

    const listSwitchMenu = ref<IManagementGroup[]>([{
      key: 0,
      label: 'Admin & Agen'
    }])

    if (menusKurir.value.length > 0) {
      listSwitchMenu.value.push({
        key: 1,
        label: 'Kurir'
      })
    }
    const valueSwitch = computed({
      get: () => store.state.switchMenu,
      set: (val) => {
        store.dispatch('setSwitchMenu', val)
      }
    })

    const hiddenSidebar = () => {
      activeSlider.value = false
    }

    return {
      activeSlider,
      hiddenSidebar,
      menusAdmin,
      listSwitchMenu,
      valueSwitch,
      menusKurir,
      version
    }
  },
  components: {
    DropdownListMenu,
    SwitchMenu,
  }
}
