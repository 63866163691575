/* eslint-disable import/extensions */
import iconDependency from '@/dependencies/iconDependency'
import menusAdmin from '@/data/source/local/json/menu-admin-agent.json'
import menusKurir from '@/data/source/local/json/menu-kurir.json'
import { forEach } from 'lodash'
import { checkPermission } from './checkPermission'

const icons = iconDependency() as any

type TItem = {
  icon?: string,
  label: string,
  route: string,
  permissions: string[],
  items?: TItem[]
}

type TMenu = {
  name: string,
  items: TItem[]
}

const checkPermissionItemMenu = (itemMenu: TItem, tempMenu: TItem[]) => {
  // console.log('itemMenu', itemMenu)
  if (itemMenu.permissions.length === 0 && checkPermission()) {
    tempMenu.push(itemMenu)
  } else if (itemMenu.permissions[0] === 'all') {
    tempMenu.push(itemMenu)
  } else {
    // const findPermission = itemMenu.permissions.find((v) => checkPermission(v))
    // if (findPermission) {
    //   tempMenu.push(itemMenu)
    // }
    // eslint-disable-next-line no-lonely-if
    if (itemMenu.permissions.length > 0) {
      let temp = true
      itemMenu.permissions.forEach((data) => {
        if (!temp) {
          return false
        }
        temp = checkPermission(data)
        // console.log('temp', [data, temp])
      })
      if (temp) {
        // console.log('tempFinal', temp)
        tempMenu.push(itemMenu)
      }
    }
  }
}

const mappingMenus = (menus: Array<TMenu>) => {
  const resultMenu: TMenu[] = []
  menus.forEach((first) => {
    const firstMenus: TItem[] = []
    first.items.forEach((second) => {
      const secondMenus: TItem[] = []
      second.icon = (second.icon) ? icons[second.icon] : icons.menuOutlineEva
      if (second.items && second.items.length === 0) {
        checkPermissionItemMenu(second, firstMenus)
      } else if (second.items && second.items.length > 0) {
        second.items.forEach((third) => {
          checkPermissionItemMenu(third, secondMenus)
        })
      }
      if (secondMenus.length > 0) {
        firstMenus.push({
          ...second,
          items: secondMenus
        })
      }
    })

    if (firstMenus.length > 0) {
      resultMenu.push({
        ...first,
        items: firstMenus
      })
    }
  })

  console.log('resultMenu', resultMenu)
  return resultMenu
}

const menuAdminAgent = mappingMenus(menusAdmin)

const menuKurir = mappingMenus(menusKurir)

export {
  menuAdminAgent,
  menuKurir
}

export type {
  TItem
}
