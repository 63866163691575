
/* eslint-disable no-extra-boolean-cast, no-restricted-syntax, no-else-return */
import {
  ref,
  defineComponent,
  getCurrentInstance
} from 'vue'
import type {
  PropType
} from 'vue'
import {
  Icon
} from '@iconify/vue'
import {
  useRouter,
  useRoute
} from 'vue-router'
import circleFill from '@iconify-icons/akar-icons/circle-fill'
import type {
  TItem
} from '@/utils/helpers/mappingMenus'

export default defineComponent({
  name: 'DropdownListMenu',
  props: {
    listMenu: {
      type: Object as PropType<TItem>,
      default: () => null
    },
    routeName: {
      type: String,
      default: () => ''
    }
  },
  setup(props) {
    const app = getCurrentInstance()
    const {
      $icon: icon
    } = app!.appContext.config.globalProperties
    const active = ref(false)
    const icons = ref({
      circle: circleFill
    })
    const router = useRouter()
    const route = useRoute()
    const listMenu = ref<TItem>(props.listMenu)
    const expandable = ref()
    const listItem = ref()

    const domShowList = () => {
      active.value = !active.value

      if (active.value) {
        expandable.value.style.height = `${listItem.value.clientHeight + 20}px`
      } else {
        expandable.value.style.height = 0
      }
    }
    const directRouter = (val = '') => {
      if (Boolean(val)) {
        router.push({
          name: val
        }).catch((err) => {
          console.log('err direct', err)
        })
      }
      if (listMenu.value.route && !Boolean(val)) {
        router.push({
          name: listMenu.value.route
        }).catch((err) => {
          console.log('err direct', err)
        })
      }
    }
    const checkIsCanRedirect = () => {
      if (listMenu.value?.items && listMenu.value.items.length > 0) {
        return false
      }
      return true
    }
    const toggleClick = () => {
      if (listMenu.value?.items && listMenu.value?.items.length > 0) {
        domShowList()
      } else {
        directRouter()
      }
    }
    const classIcon = () => ((active.value) ? 'arrow ml-auto rotate' : 'arrow ml-auto')

    const classExpandable = () => ((active.value) ? 'expandable active' : 'expandable')

    const classBody = () => {
      if (typeof route.name === 'string' && route.name === listMenu.value.route) {
        return 'py-3 px-8 flex items-center cursor-pointer body active'
      } else if (listMenu.value.items && listMenu.value.items.length > 0) {
        for (const element of listMenu.value.items) {
          const item = element
          if (typeof route.name === 'string' && route.name.includes(item.route)) {
            return 'py-3 px-8 flex items-center cursor-pointer body active'
          }
        }
      }
      return 'py-3 px-8 flex items-center cursor-pointer body'
    }

    const classItemMenu = (val: TItem) => {
      if (route.name === val.route || (typeof route.name === 'string' && route.name.includes(val.route))) {
        return 'px-12 py-3 cursor-pointer item-menu flex items-center active'
      }
      return 'px-12 py-3 cursor-pointer item-menu flex items-center'
    }

    return {
      active,
      icons,
      expandable,
      listItem,
      icon,
      classItemMenu,
      classBody,
      classExpandable,
      classIcon,
      directRouter,
      toggleClick,
      checkIsCanRedirect
    }
  },
  components: {
    Icon
  }
})
