import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex container-switch-menu" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  class: "font-semibold text-sm text-center",
  style: {"white-space":"nowrap"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("ul", _hoisted_1, [
    _createVNode("div", {
      class: ["grid gap-2 justify-center w-full", `grid-cols-${_ctx.list.length}`]
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (data) => {
        return (_openBlock(), _createBlock("div", {
          class: ["container-button", _ctx.modelValue == data.key ? 'select-switch' : 'select-switch-null'],
          key: data.key,
          onClick: ($event: any) => (_ctx.changePosition(data))
        }, [
          _createVNode("div", _hoisted_2, [
            _createVNode("p", _hoisted_3, _toDisplayString(data.label), 1)
          ])
        ], 10, ["onClick"]))
      }), 128))
    ], 2)
  ]))
}