
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed
} from 'vue'
import {
  useStore
} from 'vuex'
import {
  reportingUseCase
} from '@/domain/usecase'

export default {
  name: 'ModalUploadExcel',
  props: {
    isShow: {
      default: false
    },
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const refFileExcel = ref()
    const fileExcel = ref(null) as any
    const dialogDetailResi = computed(() => propertiesProps.isShow) as any
    const data = ref({})
    const linkDownload = `${process.env.BASE_URL}excel/Laporan Manifest Asuransi.xlsx`
    const hidedialogDetailResi = () => {
      // dialogDetailResi.value = false
      emit('hideDialog')
    }
    const handleFileUpload = () => {
      // console.log('ini ref excel', refFileExcel.value.files[0])
      if (refFileExcel.value.files[0].name.includes('.xlsx') || refFileExcel.value.files[0].name.includes('.xls')) {
        /* eslint-disable-next-line prefer-destructuring */
        fileExcel.value = refFileExcel.value.files[0]
      } else {
        refFileExcel.value.value = null
        $toast.add({
          severity: 'error',
          detail: 'Hanya Upload dengan format .xlsx/.xls',
          group: 'bc',
          life: 3000
        })
      }
    }
    const checkResponse = (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        hidedialogDetailResi()
      }
    }
    const storeData = async () => {
      const form = new FormData()
      form.append('Excel', fileExcel.value)
      const response = await reportingUseCase.uploadExcel(form)
      checkResponse(response)
      // console.log('response after post ', response)
    }
    const submitData = () => {
      storeData()
    }
    // onMounted(() => {})
    return {
      hidedialogDetailResi,
      dialogDetailResi,
      data,
      store,
      handleFileUpload,
      refFileExcel,
      submitData,
      linkDownload
    }
  }
}
