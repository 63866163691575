
/* eslint-disable vue/no-dupe-keys */
import {
  defineComponent,
  toRefs
} from 'vue'
import type {
  PropType
} from 'vue'

type TListSwitch = {
  key: number
  label: string
}

export default defineComponent({
  name: 'SwitchMenu',
  props: {
    list: {
      type: Array as PropType<Array<TListSwitch>>,
      default: () => []
    },
    modelValue: {
      type: Number
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const {
      modelValue,
      list
    } = toRefs(props)
    const changePosition = (val: TListSwitch) => {
      emit('update:modelValue', val.key)
    }
    return {
      changePosition,
      modelValue,
      list
    }
  },
})
